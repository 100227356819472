import React from 'react'

// App component
import Layout from '../../components/layout'
import PageTitle from '../../components/PageTitle'

// App content
import Smile1 from '../../assets/patientPics/smile1.jpg'
import Smile2 from '../../assets/patientPics/smile2.jpg'
import Smile3 from '../../assets/patientPics/smile3.jpg'

// App utils
import { themeStyles, presets, metrics } from '../../utils/theme'

class Page extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <div css={themeStyles.contentWidth}>
          <PageTitle
            title="When Should You See an Orthodontist"
            bannerKey={'ORTHODONTICS'}
          />
          <div css={themeStyles.textPadding}>
            <h1 css={themeStyles.blueText}>
              What age should my child first visit an orthodontist?
            </h1>
            <p>
              The American Association of Orthodontists (AAO) recommends that
              your child first see an orthodontist at the age of seven. You do
              not need a referral from your primary care dentist in order to set
              up your child’s screening with an orthodontist.
            </p>
            <p>
              Many times your primary care dentist will recognize problems that
              need to be seen by an orthodontist and then place a referral to
              our office. We absolutely love it when dentists do this because it
              allows us to evaluate the problem to determine if treatment is
              needed, and when the best time will be to begin treatment. Seeing
              an orthodontist for a screening at age 7 will make sure that
              nothing is overlooked and that all of the teeth are erupting and
              developing properly. So, your child should see an orthodontist by
              the age of seven.
            </p>
            <p>
              I know what you are thinking – “Why in the world would my child
              need braces at age 7? That seems way too young!” Here is the deal
              – you are right that most seven-year-olds will not be getting
              braces. In fact, we found that it’s quite the opposite. The
              overwhelming majority of patients who come in for a screening do
              not need early treatment. However, the American Association of
              Orthodontists does recommend a screening at the age of 7 in order
              to catch and rule out some potentially very serious situations
              that are difficult to detect to the untrained eye.
            </p>
            <h1 css={themeStyles.blueText}>
              What types of problems does the orthodontist look for at the
              initial “age 7” screening?
            </h1>
            <p>
              At your child’s age 7 screening, your orthodontist should be
              looking for a number of things. There’s no need to be worried
              about full braces at this age. We’re just screening for problems
              that could become potentially serious if left untreated. The
              problems usually include things such as the following:
            </p>
            <img
              src={Smile3}
              css={{
                [presets.Tablet]: {
                  float: 'left',
                  marginRight: metrics.defaultMargin,
                  maxHeight: '650px',
                  maxWidth: '500px',
                },
                display: 'block',
                maxHeight: '400px',
                float: 'none',
                margin: '0 auto',
                marginBottom: metrics.defaultMargin,
              }}
            />
            <ul>
              <li>
                <strong>Cross bites with a functional shift</strong>– In other
                words, a jaw that has to shift into a different bite than
                normal, which results in the lower teeth being on the outside of
                the upper teeth, or a “cross bite.” Although there are many
                problems that can be corrected when your children are older
                (with more permanent teeth), this is one problem that should be
                addressed as early as possible, and here is why – if left
                untreated, cross bites that involve your child shifting the
                lower jaw can cause issues with the way the lower jaw grows and
                potentially lead to serious bite issues later in life. If cross
                bites are left untreated at an early age, growth can become
                asymmetric. This asymmetric growth may lead to large asymmetries
                in the lower jaw later in life which often require more invasive
                treatments, such as jaw surgery, to correct when they are
                adults. But here is the good news – cross bites are often an
                easy fix at an early age, so do your child a huge favor by
                getting him or her screened by an orthodontist at the age of 7.
                Addressing cross bites at a young age will save your child from
                potentially long, expensive, and invasive treatments later in
                life!
              </li>
              <li>
                <strong>Premature loss of baby teeth</strong>– Losing baby teeth
                early may lead to other teeth tipping into open space and thus
                blocking out other permanent teeth from erupting properly. If
                caught early, your orthodontist will be able to place a simple
                “space maintainer” appliance that will prevent adjacent teeth
                from tipping into the space. However, if not caught early, then
                teeth may shift and block out the eruption of other permanent
                teeth, thus significantly increasing the overall treatment needs
                and cost for care. Catching this problem early can save your
                child time in braces and save you money!
              </li>
              <li>
                <strong>Over-retained baby teeth</strong>– Not losing teeth in
                the correct order, or not losing teeth on time, may result in
                the permanent teeth erupting into unfavorable positions or even
                becoming “stuck” up in the bone. There are simple, non-invasive
                early treatments (which may not even require braces) that may
                prevent much more involved and expensive treatments later in
                life.
              </li>
              <li>
                <strong>Thumb or finger sucking habits</strong>– Thumb habits
                will cause teeth to move in unwanted ways, and can even cause
                jaw growth problems. Thumb habits should be pointed out to your
                orthodontist immediately. Your orthodontist will use a
                combination of coaching and reminder appliances to help your
                child discontinue the habit.
              </li>
              <li>
                <strong>Severe dental crowding</strong>– Many times we don’t
                even need to put early braces on your child if he or she has
                severe crowding at the age of 7. However, if there is not enough
                room in the mouth for all of your child’s teeth to fit, then we
                will talk about the potential need for tooth extractions and the
                appropriate timing and age for those extractions. Your child
                will likely need braces later on, but there are more
                conservative (and proven) techniques that we can use at an early
                age to significantly improve the alignment of your child’s
                teeth, without even having to place early braces!
              </li>
              <li>
                <strong>Impacted teeth</strong>– (i.e. teeth that are stuck in
                the bone). If your child has teeth that are impacted, then your
                orthodontist will develop a plan to allow these teeth to erupt
                into the mouth as easily and painlessly as possible.
              </li>
              <li>
                <strong>Teeth that “stick out” too far</strong>– If your child’s
                adult teeth stick out too far, then he or she may be at risk for
                injuring and damaging these teeth.
              </li>
              <li>
                <strong>Children who are being “teased” at school</strong>–
                Whether it be teeth that are crowded, stick out too far, teeth
                that are spaced far apart, or other concerns, bullying is very
                real and damaging to kids in today’s world. Names such as
                “toothy” or “bucky” may seem harmless but could be devastating
                to your child’s self-confidence. According to Facts for Life,
                Volume 4 online: “Early experiences provide the base for the
                brain’s organizational development and functioning throughout
                life. They have a direct impact on how children develop learning
                skills as well as social and emotional abilities.” If your child
                is getting teased or is self-conscious at an early age, this may
                be a great reason for early treatment.
              </li>
            </ul>
            <p>
              All of the issues listed above are exactly what we look for at
              your child’s early screening. In fact, some of these items don’t
              even require the placement of orthodontic appliances. In some of
              these potential early issues, we can simply have your primary care
              dentist remove a few baby teeth that are causing problems for
              future adult teeth, without even placing braces or appliances. The
              bottom line is this – we will tell you at your initial orthodontic
              screening appointment if your child could benefit from early
              orthodontic treatment. I cannot stress the importance enough of
              your child getting screened by the age of 7.
            </p>

            <h1 css={themeStyles.blueText}>
              Why are some kids getting braces earlier and earlier?
            </h1>
            <p>
              So now you know the importance of every child getting a screening
              by an orthodontist at the age of 7. In fact, if you have a child
              over the age of 7, you should stop reading this immediately and
              call your orthodontist to schedule an exam today. Really. Catching
              things early CAN save you from lengthier, more complicated, and
              more expensive treatment later on! HOWEVER, in the same breath I
              use to inform parents that an early screening at the age of 7 is
              the best way to help your child potentially avoid big orthodontic
              problems later on, I also mention that most children will NOT need
              braces until 11 to 13 years of age. In order to understand why,
              you should first know a little bit about the history of
              orthodontic treatment (hang in there, folks…this is important and
              worth your time to read!).
            </p>
            <img
              src={Smile2}
              css={{
                [presets.Tablet]: {
                  float: 'right',
                  marginLeft: metrics.defaultMargin,
                  maxHeight: '400px',
                  maxWidth: '500px',
                },
                display: 'block',
                maxHeight: '400px',
                float: 'none',
                margin: '0 auto',
                marginBottom: metrics.defaultMargin,
              }}
            />
            <p>
              In the 1960s, orthodontists rarely treated children at a young age
              because the technology was not sophisticated enough to prevent
              adult teeth from needing to be extracted later on. So almost every
              kid received one phase of braces at around the age of 12, and
              nearly every one of these kids had to get permanent teeth removed.
            </p>
            <p>
              In the 1980s, new technology in the form of “memory wires” was
              introduced to orthodontics. These new memory wires were used with
              appliances capable of expanding the patients’ jaws, which opened
              up an entirely new group of patients – younger children between
              the ages of 7 and 10. So in the 1980s, every fourth grade kid
              started to get braces with the hopes that they would not need
              permanent teeth removed later on.
            </p>
            <p>
              Fast forward to present day. Now we have over 40 years of early
              treatment results in the orthodontic literature, which proves that
              early treatment does not really make a difference in many cases! A
              high quality research study conducted at the University of North
              Carolina provided 10 years’ worth of research, and what they found
              shocked the orthodontic world. In fact, many orthodontists began
              asking the question, “do kids really benefit from early
              treatment?”
            </p>
            <p>
              So what were the findings from the study that completely changed
              the orthodontic world? The study showed the treatment outcomes
              from two different groups of patients (both groups with similar
              “starting” bites) – the first group consisted of patients that
              waited until they were 11 to 13 years of age to start braces, and
              the other group had two separate phases of braces. Can you guess
              what they found?
            </p>
            <p>
              The group who waited until 11 to 13 had nearly identical results
              as those who received two separate rounds of braces. Confused? You
              are not alone. Millions of parents across the country are confused
              every year when they get different opinions from orthodontists
              regarding early treatment.
            </p>
            <p>
              So the question becomes this – who is right? – the orthodontist
              who tells you to wait on treatment or the orthodontist who
              recommends to start early? It will always depend on the risks
              involved in your child’s individual case with waiting versus
              starting treatment early.
            </p>
            <p>
              Although starting early is crucial in some cases, remember that
              the most current research shows that most children between the
              ages of 7 to 10 will NOT need early treatment. This is based on
              solid peer-reviewed high quality research – the same literature
              and research in which I was taught and trained as an orthodontic
              fellow at the world-famous Mayo Clinic. So let me ask you this –
              if you could get the exact same (or better) result with less time
              in braces for your child for a lower overall cost, would you
              choose that option? Me too.
            </p>

            <h1 css={themeStyles.blueText}>
              I’m confused. My child needs to see an orthodontist by age 7 but
              will likely not need early treatment? So what’s the point of the
              screening?
            </h1>
            <p>
              At this point, you are probably confused. If most kids don’t need
              treatment at an early age, then why in the world should an
              orthodontist screen your child at age 7? Here is what you need to
              know. I agree with the American Association of Orthodontists that
              your child should see an orthodontist no later than the age of 7
              for a screening only in order to determine if he or she can
              benefit from early treatment.
            </p>
            <p>
              The good news is this – in my experience, only 5-10% of young
              patients who get these screenings actually need early treatment.
              For those 5-10%, early treatment can literally prevent the need
              for surgery and extremely expensive orthodontic treatments later
              in life. These 5-10% who need treatment will likely have one of
              the following:
            </p>
            <ul>
              <li>Cross bites</li>
              <li>Severe crowding</li>
              <li>Jaw alignment issues</li>
              <li>Early loss of baby teeth</li>
              <li>Late loss of baby teeth</li>
              <li>Impacted or missing teeth</li>
              <li>Finger and thumb habits</li>
              <li>Protruding teeth</li>
            </ul>
            <p>
              If your child has one of the above, then we will be able to catch
              it early in order to come up with a game plan. Some of the above
              problems will require early braces, and some will simply require
              the placement of simple appliances. If you don’t get a screening
              early, then you really are taking a risk for your child, no matter
              how small it may be.
            </p>

            <h1 css={themeStyles.blueText}>
              So which is it, early treatment or not?
            </h1>
            <p>
              The bottom line is this:{' '}
              <strong>
                you need to get your child screened by an orthodontic specialist
                no later than age 7.
              </strong>{' '}
              You do not need a referral from your dentist to schedule this
              screening. And remember, 90-95% of the time your child will not
              require early treatment. Instead, we will monitor their growth
              until the perfect time for braces, and only if they are absolutely
              necessary.
            </p>
            <p>
              If your orthodontist tells you that everyone can benefit from
              early treatment, the simple truth is that we have better research
              today that has proven this is not the case. I tell parents in
              these situations that we can get the same result, with less time
              in braces and less money invested, if we wait until the perfect
              time for braces.
            </p>

            <h1 css={themeStyles.blueText}>What is the best age for braces?</h1>
            <p>
              So you’ve had your child screened by the orthodontist and now you
              are wondering what the best age for treatment will be. If your
              child is one the few who does need early treatment, typically the
              best age will depend on which permanent teeth have already erupted
              into the mouth. Usually, early treatment will be started between
              the ages of 7 to 10, but this can vary.
            </p>
            <p>
              If early treatment is not indicated, then the best age for
              treatment will be determined by two main factors – the number of
              permanent teeth present, and your child’s state of growth. If your
              child does not have the proper number of permanent teeth present
              and we put braces on too early, then we will waste valuable time
              just sitting in braces while we wait for the rest of the adult
              teeth to come into the mouth. This can add a significant amount of
              time (many years actually) onto the overall treatment time.
            </p>
            <img
              src={Smile1}
              css={{
                [presets.Tablet]: {
                  float: 'left',
                  marginRight: metrics.defaultMargin,
                  maxHeight: '450px',
                  maxWidth: '500px',
                },
                display: 'block',
                maxHeight: '400px',
                float: 'none',
                margin: '0 auto',
                marginBottom: metrics.defaultMargin,
              }}
            />
            <p>
              The second factor that we use to determine the ideal age for
              treatment is growth. If your child has a{' '}
              <strong>jaw that is back too far</strong>, then we want to begin
              treatment during your child’s peak growth period. It is VERY
              important to treat your child during this peak growth period,
              because after growth is complete, the options to correct this type
              of bite non-surgically become very limited.
            </p>
            <p>
              In fact, after growth is complete, the two options to correct
              underlying skeletal discrepancies become limited to jaw surgery,
              tooth extractions or simply choosing to leave the jaws misaligned.
              So if your child has a jaw that is recessed, or “too far back,”
              then treatment before growth is complete becomes crucial for a
              successful outcome.
            </p>
            <p>
              However, in cases where your child’s{' '}
              <strong>lower jaw is too far forward</strong> (i.e. an
              “underbite”) then treatment timing becomes important for other
              reasons. Underbites in children ages 7 to 10 can often be
              corrected with braces and some special appliances. However,
              underbites that persist through the teenage years are different.
              The best thing to do in these cases is to allow growth to near
              completion before making definitive orthodontic treatment
              decisions. Otherwise, the orthodontist may be able to correct the
              underbite with braces, but your child’s jaw may continue to grow
              back into an underbite. For that reason, most orthodontists will
              wait until growth nears completion prior to beginning orthodontic
              care on teenage patients with an underbite.
            </p>
            <p>
              In{' '}
              <strong>adults, the best age for braces is always “now.”</strong>{' '}
              The reason I say “now” isn’t based on my own personal feelings
              toward giving people better smiles. Rather, the reason is it’s
              easier to correct problems before they get worse. Allow me to
              explain: Your teeth will continue to “shift” over time (regardless
              of whether you had braces as a kid or not). Honestly, it’s just
              part of aging and being human. If you have some lower crowded
              teeth, it won’t spontaneously resolve and look straight.
            </p>
            <p>
              In fact, the teeth will gradually get more crowded with time. It’s
              just part of life. This crowding can potentially make your teeth
              more difficult to clean, cause abnormal wear patterns on your
              teeth, lead to abnormal shifting of your jaw, and many other
              issues that can be difficult to remedy.
            </p>
            <p>
              The idea is simple though. Waiting will just allow your teeth to
              shift more over time. If you correct your bite and teeth alignment
              when you first notice the problems, then you will spend less
              overall time in braces or invisalign.
            </p>
            <p>
              For those of you worried about going to a clinic full of
              teenagers, I have some great news for you. Braces really aren’t
              “just for kids” anymore.{' '}
              <strong>
                Adult orthodontic treatment is becoming more popular with
                esthetic options
              </strong>{' '}
              such as invisalign and clear braces. The two most common reasons
              that we see adults in our clinic are:
            </p>
            <ol>
              <li>
                “I had braces as a kid, I lost/broke/don’t wear my retainer, and
                now my teeth have shifted,” and
              </li>
              <li>
                “I never had braces as a kid and now I would like to do
                something about my teeth.”
              </li>
            </ol>
            <p>
              If you are an adult thinking about getting braces or invisalign,{' '}
              <strong>you are not alone.</strong> It has become very popular and
              common for adults to seek out braces or invisalign from an
              orthodontist. If you are not happy with your smile, then why wait
              another minute?
            </p>

            <h1 css={themeStyles.blueText}>
              So my child DOESN’T need early braces. Now what?
            </h1>
            <p>
              If your child doesn’t need early braces, the next step will be to
              put your child in our{' '}
              <strong>“Tooth Guidance Recall Program.”</strong> In this program,
              we will see your children on a 6 to 12 month basis in order to
              continually monitor the eruption of their teeth as well as the
              growth of their jaws. It is very important for an orthodontist to
              continually monitor your child during this phase in order to catch
              any problems that might occur along the way.
            </p>
            <p>
              Most of the time, the orthodontist will simply make sure the adult
              teeth are erupting properly and at the right time. The main job of
              your orthodontist should be to start treatment at a time that will
              allow your child to be in braces for the LEAST amount of time
              possible while still getting the BEST possible outcome in the most
              COST-EFFECTIVE way for you as a parent.{' '}
              <strong>
                In the majority of cases, simply waiting until the right time
                will significantly reduce the amount of time your child will
                need to be in braces.
              </strong>
            </p>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Page
